import { render, staticRenderFns } from "./Constância.vue?vue&type=template&id=565b1c50&scoped=true&"
import script from "./Constância.vue?vue&type=script&lang=js&"
export * from "./Constância.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "565b1c50",
  null
  
)

export default component.exports